import { useContext } from "react";
import UserContext from "../utils/UserContext";

const Footer = () => {
  const { user } = useContext(UserContext);
  return (
    <h4 data-testid="footer" className="p-5 bg-white text-center">
      This site is developed by {user.name} - {user.email}
    </h4>
  );
};

export default Footer;
